import { ExtendedModel, model, prop } from "mobx-keystone";
import DirtyableWithId from "./DirtyableWithId";

type TreatRate = {
  percentage: number;
  description: string;
  marketSector?: "additives" | "base oils";
};

@model("collab/FormulationDatahub")
class FormulationDatahub extends ExtendedModel(DirtyableWithId, {
  plantFormulationCode: prop<string>(),
  masterFormulationCode: prop<string>(),
  productVariantCode: prop<string>(),
  fusionPlantCode: prop<string>(),
  componentRCode: prop<string>(),
  alternativeRCode: prop<string>(),
  version: prop<string>(),
  mass: prop<number>(),
  fusionPlantId: prop<string>(),
  formulationStatus: prop<string>(),
  treatRates: prop<{ [key: string]: TreatRate }>(),
  plantFormulationPlant: prop<number>(),
  // Let users explicitly pick formulation/s to import
  toImport: prop<boolean>(false),
}) {}

export default FormulationDatahub;
